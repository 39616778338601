import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`VQN Dashboard`}</h1>
    <p>{`Links:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/demos/vqn-dash/pcd"
        }}>{`./pcd`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/demos/vqn-dash/point-cloud-viz"
        }}>{`./point-cloud-viz`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/demos/vqn-dash/rgbd-viewer"
        }}>{`./rgbd-viewer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/demos/vqn-dash/simple-cubes"
        }}>{`./simple-cubes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/demos/vqn-dash/three"
        }}>{`./three`}</a></li>
    </ul>
    <p>{`Details:`}</p>
    <ul>
      <li parentName="ul">{`coming soon.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      